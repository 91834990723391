import React from 'react';

import logo from '../assets/images/placelogo.webp';

const Header = props => (
  <header
    id="header"
    className="alt"
    style={{ paddingBottom: '0px', paddingTop: '2em' }}
  >
    <span className="logo">
      <img src={logo} alt="" style={{ maxHeight: '200px' }} />
    </span>
    <h2>Population-level crisis communications, solved.</h2>
    <p>
      Privacy assured, location aware, multi-channel information discovery and
      delivery, at extreme scale.
    </p>
  </header>
);

export default Header;
