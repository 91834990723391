import React from 'react';
import Scrollspy from 'react-scrollspy';
import Scroll from './Scroll';

const Nav = props => (
  <nav id="nav" className={props.sticky ? 'alt' : ''}>
    <Scrollspy
      items={['background', 'decentralise', 'diversity', 'privacy', 'cta']}
      currentClassName="is-active"
      offset={-300}
    >
      <li>
        <Scroll type="id" element="background">
          <a href="#">Background</a>
        </Scroll>
      </li>
      <li>
        <Scroll type="id" element="decentralise">
          <a href="#">Decentralisation</a>
        </Scroll>
      </li>
      <li>
        <Scroll type="id" element="diversity">
          <a href="#">Diversity</a>
        </Scroll>
      </li>
      <li>
        <Scroll type="id" element="privacy">
          <a href="#">Privacy</a>
        </Scroll>
      </li>
      <li>
        <Scroll type="id" element="cta">
          <a href="#">Get Started</a>
        </Scroll>
      </li>
    </Scrollspy>
  </nav>
);

export default Nav;
