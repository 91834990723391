import { Link } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';
import { Waypoint } from 'react-waypoint';
import peconcept from '../assets/images/PlaceEarth-Concept.svg';
import Header from '../components/Header';
import Layout from '../components/layout';
import Nav from '../components/Nav';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stickyNav: false,
    };
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }));
  };

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }));
  };

  render() {
    return (
      <Layout>
        <Helmet title="Place.Earth | Population-level decentralised communications platform." />
        <Header />
        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        ></Waypoint>
        <Nav sticky={this.state.stickyNav} />
        <div id="main">
          <section id="background" className="main">
            <div className="content">
              <header className="major">
                <h2>Born from fire and flood</h2>
              </header>
              <p>
                The last few years have been eventful. Fires, floods, pandemic,
                extreme weather, social upheaval, terrorism, extremism, and
                seismic shifts in social patterns. People are increasingly
                connected and increasingly overwhelmed by the constant barrage
                of information. The organisations responsible for conveying
                critical information to the public have found their role
                increasingly important yet also increasingly difficult as the
                need for information and the chaos of information-overload
                clash.
              </p>
              <p>
                Social media platforms and traditional web sites are failing to
                resolve this situation. Most organisations cast their
                information onto the web and into the social wilderness and hope
                that the public will be able to find it. Information scrolls by
                on platforms like Twitter, mixed in with advertising and
                interest-driven content, requiring consumers to not only have
                subscribed to the right feeds but also to have their eyes
                constantly glued to their screens to receive the critical
                messages being communicated. Often social media 'groups' become
                informal information aggregators, the efficacy of these groups
                only as good as the searching ability and responsiveness of
                their members.
              </p>
              <p>
                Alternatives such as bespoke mobile applications <i>are</i>{' '}
                effective to gain cut through but they are very costly to
                develop and maintain, and are frequently designed to suit a
                single organisation's communication strategy; leaving out
                information from adjacent organisations that is critical to
                consumers. Centralised management of comms across multiple
                disparate groups typically creates too heavy an administrative
                burden.
              </p>
              <h3>To solve these problems a platform is needed that:</h3>
              <div
                style={{
                  width: 'fit-content',
                }}
              >
                <ul style={{ textAlign: 'left' }}>
                  <li>Aggregates information from multiple sources</li>
                  <li>Allows decentralisation of publishing</li>
                  <li>Categorises information by topic</li>
                  <li>Targets information based on location</li>
                  <li>Allows both information discovery and delivery</li>
                  <li>Can be consumed via multiple channels</li>
                </ul>
              </div>

              <p>
                <b>That's where Place.Earth comes in.</b>
              </p>
            </div>
          </section>

          <section id="decentralise" className="main">
            <div className="content">
              <header className="major">
                <h2>Decentralised Communications</h2>
              </header>
              <p>
                One of the primary challenges in population-scale crisis
                communications is the multitude of providers involved in
                responding to the crisis. Beyond the state-level government
                bodies with primary responsibility are secondary bodies, local
                government, volunteer organisations such Red Cross, federal
                bodies such as the Bureau of Meterology and Department of
                Defence, corporations with responsibility for electricity,
                water, and private roads, police, transport operators, and
                countless community groups and businesses who are all critical
                to those on the ground.
              </p>
              <p>
                <b>
                  Centralising and coordinating communications across all of
                  these groups to the point where concise location-specific
                  messages can be provided to the affected population seems an
                  impossible task.
                </b>{' '}
              </p>
              <p>
                The Place.Earth platform provides a mechanism to{' '}
                <b>decentralise</b> communications. An unlimited number of
                entities may be authorised to contribute to the flow of
                information to a particular audience{' '}
                <i> without requiring central coordination</i>. Tagged with
                location and topic, the information is then categorised and
                aggregated, resulting in a single view of all information
                relevant to any particular location.
              </p>
              <div
                style={{
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                <img
                  src={peconcept}
                  alt="Diagram of distributed communications model"
                  style={{
                    width: '100%',
                    maxWidth: '800px',
                  }}
                />
              </div>

              <footer className="major"></footer>
            </div>
          </section>

          <section id="diversity" className="main">
            <header className="major">
              <h2>Multi-dimensional diversity</h2>
              <p>Place.Earth strives to 'meet people where they are'</p>
            </header>

            <p>
              Any population-scale tool must be designed to allow for the
              diversity of the given population. Flexibility to publish
              information that adapts to the consumer's situation is critical.
            </p>
            <p>
              Language is the obvious starting point. Place.Earth allows any
              message to be published in multiple languages, with the system
              able to deliver the required language to the consumer based on
              their personal preference. Additionally, automated translation
              tools are able to be leveraged to automatically translate into any
              common language.
            </p>
            <p>
              Beyond language, technological diversity is the next challenge.
              Place.Earth is able to deliver information via multiple channels,
              including:
            </p>
            <div
              style={{
                width: 'fit-content',
              }}
            >
              <ul style={{ textAlign: 'left' }}>
                <li>Mobile applications</li>
                <li>Web sites</li>
                <li>Email</li>
                <li>SMS Messages</li>
                <li>WhatsApp Messages</li>
                <li>
                  Computer generated or pre-recorded traditional telephone
                  messages
                </li>
                <li>Many other mechanisms via software integration</li>
              </ul>
            </div>
          </section>

          <section id="privacy" className="main">
            <header className="major">
              <h2>Privacy, Managed</h2>
              <p>
                The Place.Earth platform allows location-specific information to
                be discovered and delivered, <i>without</i> the publishing
                organisation knowing <i>anything</i> about the recipient.
              </p>
            </header>
            <div className="content">
              <p>
                All data flowing to publishers is automatically anonymised;
                removing the requirement for publishing organisations to
                interact with sensitive consumer information such as location or
                personal identifiers. This dramatically decreases compliance
                requirements, allowing the focus to be on delivering the message
                and providing the service.
              </p>
              <p>
                This is accomplished by publishing all information to a
                location, and a topic. Any personal details required for
                managing the delivery of the information are kept separate and
                secure. All sensitive information required for information
                delivery (such as phone numbers and device IDs), is managed
                within the platform and never shared with the publisher unless
                an explicit informed choice is made by the end user (for example
                to register themselves as requiring special assistance).
              </p>
              <p>
                Additionally, Place.Earth has no hidden agenda. The platform
                derives it's income from the organisations who pay for
                subscriptions to publish information. End user data is not used
                for targeting advertisements or sold to other organisations,
                unlike social or other media platforms.
              </p>
            </div>
          </section>

          <section id="cta" className="main special">
            <header className="major">
              <h2>Like to know more?</h2>
              <p>
                Contact our team today and let's work together to make sure
                everyone receives the message.
              </p>
            </header>
            <footer className="major">
              <ul className="actions">
                <li>
                  <a
                    href="mailto:enquiries@place.earth?subject=Place.Earth Enquiry"
                    className="button special"
                  >
                    Contact Us Now
                  </a>
                </li>
              </ul>
            </footer>
          </section>
        </div>
      </Layout>
    );
  }
}

export default Index;

/*
<ul className="features">
              <li>
                <span className="icon major style1 fa-code"></span>
                <h3>Ipsum consequat</h3>
                <p>
                  Sed lorem amet ipsum dolor et amet nullam consequat a feugiat
                  consequat tempus veroeros sed consequat.
                </p>
              </li>
              <li>
                <span className="icon major style3 fa-copy"></span>
                <h3>Amed sed feugiat</h3>
                <p>
                  Sed lorem amet ipsum dolor et amet nullam consequat a feugiat
                  consequat tempus veroeros sed consequat.
                </p>
              </li>
              <li>
                <span className="icon major style5 fa-diamond"></span>
                <h3>Dolor nullam</h3>
                <p>
                  Sed lorem amet ipsum dolor et amet nullam consequat a feugiat
                  consequat tempus veroeros sed consequat.
                </p>
              </li>
            </ul>

            <ul className="statistics">
              <li className="style1">
                <span className="icon fa-code-fork"></span>
                <strong>5,120</strong> Etiam
              </li>
              <li className="style2">
                <span className="icon fa-folder-open-o"></span>
                <strong>8,192</strong> Magna
              </li>
              <li className="style3">
                <span className="icon fa-signal"></span>
                <strong>2,048</strong> Tempus
              </li>
              <li className="style4">
                <span className="icon fa-laptop"></span>
                <strong>4,096</strong> Aliquam
              </li>
              <li className="style5">
                <span className="icon fa-diamond"></span>
                <strong>1,024</strong> Nullam
              </li>
            </ul>
*/
